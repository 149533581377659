
import { defineComponent, onBeforeMount } from 'vue'

// Components
import SButton from '@/common/components/SButton/index.vue'
import SLoader from '@/common/components/SLoader/index.vue'

// Composable
import { useTitle } from 'vue-composable'
import usePretension from '@/modules/PretensionsModule/composable/usePretension'

// icons
import iArrowLeft from '@/assets/icons/ArrowLeft.svg'

export default defineComponent({
    components: {
        's-button': SButton,
        's-loader': SLoader,
        'icon-arrow-left': iArrowLeft,
    },
    setup() {
        useTitle('Просмотр претензии')

        const { pretension_state, loadPretension, getFileTypeByUrl, downloadFile } = usePretension({
            is_create: false,
            is_update: false,
        })
        onBeforeMount(loadPretension)

        return { pretension_state, getFileTypeByUrl, downloadFile, loadPretension }
    },
})
